module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"engie-italia","short_name":"engie","start_url":"/","background_color":"#FFFFFF","theme_color":"#009DE9","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d37766cca38260815f369534a78b5aae"},
    },{
      plugin: require('../plugins/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5QQ9LW","includeInDevelopment":true,"dataLayerName":"dataLayer","routeChangeEventName":"navigazione","enableDataOtIgnore":true,"defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../plugins/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5VZCF7DG","includeInDevelopment":true,"dataLayerName":"dataLayer","routeChangeEventName":"navigazione","selfHostedOrigin":"https://tagging.engie.it","defaultDataLayer":null,"enableWebVitalsTracking":false,"enableDataOtIgnore":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
